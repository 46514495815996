import AppRouter from "./config/router";
import { ConfigProvider } from "antd";
import Teko from "./assets/fonts/Teko.ttf";

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#184E51",
          fontFamily: Teko,
        },
      }}
    >
      <AppRouter />
    </ConfigProvider>
  );
}

export default App;
