import { useState } from "react";
import {
  Container,
  InvestorModal,
  PartnershipModal,
  ServicesCard,
  ThemeButton,
} from "../../components/components";
import {
  cloud,
  down_outlined_arrow,
  otcmarkets,
  reelcause,
  right_black_icon,
  star,
  sun,
  filled_star,
  sketch_annotation,
  filled_star_2,
  right_arrow_icon,
  arrow_icon,
  filled_green_star,
} from "../../assets/icons/icons";
import { kbs2024 } from "../../assets/videos/videos";
import {
  association_tag,
  hhoen,
  hienergy_logo,
  home_frame,
  hypower_logo,
  imagincreate_cover,
  profile_image,
  // reelcausebio_logo,
  reelcauseMEnergy,
  reelcauseenergy_logo,
} from "../../assets/images/images";
import { Col, Input, Row } from "antd";
import { ServicesData } from "../../constants/constants";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";

const Construction = () => {
  const [investorModal, setInvestorModal] = useState(false);
  const [partnershipModal, setPartnershipModal] = useState(false);
  const navigate = useNavigate();
  return (
    <h1
      style={{
        marginTop: "100px",
        textAlign: "center",
      }}
    >
      {/* We are updating our website. We apologize for any inconvenience. */}
      Website is under construction.
    </h1>
  );
};

export default Construction;
