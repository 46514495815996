import { useLayoutEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import {
  About,
  Home,
  HydrogenDrone,
  HydrogenEnergy,
  HydrogenMethod,
  P2gSystem,
  PossessedPatents,
  SmallWindPower,
  SmartFarm,
} from "../pages/pages";
import Construction from "../pages/home/construction";

function ScrollToTop() {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function AppRouter() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Construction />} />
        {/* <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/hydrogen-drone" element={<HydrogenDrone />} />
        <Route exact path="/hydrogen-energy" element={<HydrogenEnergy />} />
        <Route exact path="/small-wind-power" element={<SmallWindPower />} />
        <Route exact path="/smart-farm" element={<SmartFarm />} />
        <Route exact path="/hydrogen-method" element={<HydrogenMethod />} />
        <Route exact path="/p2g-system" element={<P2gSystem />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
